import { useState, useEffect } from "react";
import { motion } from "framer-motion"; // For smooth animations
import { X } from "lucide-react"; // Close icon

const OfficeMovePopup = () => {
    const [visible, setVisible] = useState(true);

    // Auto-close after 10 seconds
    useEffect(() => {
        const timer = setTimeout(() => setVisible(false), 10000);
        return () => clearTimeout(timer);
    }, []);

    if (!visible) return null; // If not visible, don't render anything

    return (
        <motion.div 
            className="popup"
            initial={{ opacity: 0, y: 50 }} // Animation starts from below
            animate={{ opacity: 1, y: 0 }} // Moves up smoothly
            exit={{ opacity: 0, y: 50 }} // Moves down when closing
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <button className="popup-close" onClick={() => setVisible(false)}>
                <X size={20} />
            </button>
            <h3 className="popup-title">🎉 We’ve Moved! 🎉</h3>
            <p className="popup-text">We are now at our new office:</p>
            <p className="popup-address text-[#000]">📍
            Constantia Square Office Park 
            Block 4,
            526 16th Road, Randjespark
            Midrand 
            1685
            </p>
        </motion.div>
    );
};

export default OfficeMovePopup;
