import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { Slide } from 'react-awesome-reveal'
import Boy from '../assets/contactimage.png'
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneVolume } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import ContactDetail from './ContactDetail';
function Contact() {
  const fire = (message, type) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'right ',
        horizontal: 'right'
      }, variant: type
    })
  }
  const [data, setdata] = useState({
    name: '',
    mail: '',
    message: ''
  })
  const [error, seterror] = useState('')
  const sendTo = () => {

    for (const key in data) {
      if (data[key] == '') {
        seterror('0')
        console.log('cc');
        return;
      }

    }
    console.log('ok');

    fetch('https://backend.shesharide.co.za/api/contact_web', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data sent successfully:', data);
        if (data.message == 'success') {
          fire('success', 'success')
          handleShowModal()
        }

      })
      .catch(error => {
        console.error('Error sending data:', error);
        fire('Something Went Wrong!!', 'error')
        // Handle error as needed
      });
    setdata({
      name: '',
      mail: '',
      message: ''
    })

  }
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
    <div>
      <SnackbarProvider
        autoHideDuration={5000}
      />
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center gap-10 overflow-hidden p-6 lg:p-12">
        <Slide direction="left" triggerOnce>
          <div className="flex justify-center">
            <img src={Boy} alt="Contact Illustration" className="max-w-full rounded-xl shadow-lg" />
          </div>
        </Slide>

        <Slide direction="right" triggerOnce>
          <div className="md:p-5 p-5 lg:p-10">
            <h3 className="text-black text-2xl md:text-3xl lg:text-4xl font-extrabold uppercase">
              We're here to help <br />
              <span className="text-[#f62f02]">Get in touch</span>
            </h3>
            <p className="lg:text-2xl text-lg my-4 leading-8 lg:leading-10 text-gray-700">
              Whether you have a question, need support, or just want to give feedback, we're here to listen. Reach out today and let's make your Shesha experience better, together.
            </p>

            {/* Contact Details */}
            <ContactDetail />
          </div>
        </Slide>
      </section>


    </div>
  )
}

export default Contact