import { FaPhoneVolume } from "react-icons/fa";
import { IoLocationSharp, IoLogoWhatsapp } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";


function ContactDetials() {
    return <>
        <div className="p-6 bg-black rounded-2xl shadow-2xl border border-gray-800 max-w-lg mx-auto">
            <div className="mb-5 flex items-center space-x-3 hover:bg-gray-800 p-3 rounded-lg transition">
                <IoLocationSharp className="text-[#f62f02] text-2xl" />
                <p className="text-gray-300 font-medium">
                    <span className="text-white font-bold">Office Address:</span><br />
                    Constantia Square Office Park 
                    Block 4,
                    526 16th Road, Randjespark
                    Midrand 
                    1685
                </p>
            </div>

            <div className="mb-5 flex items-center space-x-3 hover:bg-gray-800 p-3 rounded-lg transition">
                <FaPhoneVolume className="text-[#f62f02] text-2xl" />
                <p className="text-gray-300 font-medium">
                    <span className="text-white font-bold">Office Line:</span> 010 157 8680
                </p>
            </div>

            <div className="mb-5 flex items-center space-x-3 hover:bg-gray-800 p-3 rounded-lg transition">
                <MdMarkEmailUnread className="text-[#f62f02] text-2xl" />
                <p className="text-gray-300 font-medium">
                    <span className="text-white font-bold">Email:</span> info@shesharide.co.za
                </p>
            </div>

            <div className="flex items-center space-x-3 hover:bg-gray-800 p-3 rounded-lg transition">
                <IoLogoWhatsapp className="text-[#f62f02] text-2xl" />
                <p className="text-gray-300 font-medium">
                    <span className="text-white font-bold">WhatsApp:</span> 061 453 2260 / 071 042 0019
                </p>
            </div>
        </div>
    </>
}

export default ContactDetials;